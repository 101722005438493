import { Component, OnInit, TemplateRef } from '@angular/core'
import { DataService } from './data.service'
import { ActivatedRoute } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ConstantsService } from 'src/app/services/constants.service'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Resp } from 'src/app/interfaces/response'
import { Events } from 'src/app/models/events'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { formatDate } from '@angular/common'
import moment from 'moment'
import { ApiService } from 'src/app/services/api.service'
@Component({
    selector: 'app-monthly-view',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
    days = [
        'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
    ]
    parallelName:any
    userBalance:any
    requiredBalance:any
    datafound: any
    mentors: any = []
    inspection: any
    mentorId: any = ''
    mentorList: any
    paymentDetail: any = {}
    fetching: boolean = false
    loadingCalendar = false
    packageAmount: number = 0
    isPack2Active: boolean = false
    isPack1Active: boolean = false
    confirmPayment: boolean = false
    paymentFG: FormGroup
    confirmPaymentDetails: any = {}
    moment = moment
    scheduled = '#3B71CA'
    inProgress = '#E4A11B'
    completed = '#198754'
    canceled = '#ff0e0e'
    month: string = ''
    year: string = ''
    fIlterMonth: any = new Date();
    fIlterYear: any = new Date()
    currentdDate = moment(new Date())
    teamIds: Array<any> = []
    teamsList: Array<any> = []
    color = 'black'
    filters: any = {
        startDate: moment().clone().startOf('month').toDate(),
        endDate: moment().clone().endOf('month').toDate(),
    }
    dataStatus = 'fetching'
    allEventsList: Array<any> = []
    eventsList: Array<any> = []
    calendar: any = [];
    breadCrum = [
        {
            link: '/mentor/calendar',
            value: 'Calendar'
        }
    ]
    waiting: {
        search: boolean
        save: boolean
        userStatus: Array<any>
    }

    eventsForm: FormGroup

    selectedIndex: number
    inspectionIndex: number
    modalTitle = ''
    modalRef: BsModalRef
    payReqModal: BsModalRef
    editorConfig: any = ConstantsService.EDITOR_CONFIG
    constructor(
        private ds: DataService,
        private ms: BsModalService,
        public route: ActivatedRoute,
        public cs: ConstantsService,
        public ui: UIHelpers,
        private fb: FormBuilder,
        private alert: IAlertService,
        public api: ApiService
    ) {
        this.waiting = {
            search: false,
            save: false,
            userStatus: []
        }
        this.eventsForm = this.fb.group({
            id: new FormControl(null),
            title: new FormControl('', [Validators.required, Validators.maxLength(100)]),
            content: new FormControl('', [Validators.required]),
            startDate: new FormControl(null, [Validators.required]),
            endDate: new FormControl('', [Validators.required]),
            startTime: new FormControl(null, [Validators.required]),
            endTime: new FormControl(null, [Validators.required]),
            teamIds: new FormControl([], [Validators.required])
        })
        ds.getMentor().subscribe((resp) => {
            this.mentorList = resp.data
        })

        this.paymentFG = new FormGroup({
            token: new FormControl('', Validators.required)
        })

        this.parallelName = this.api.user.parallel.name
        const params = { ...this.filters }

        // this.ds.getMyWallet(params).subscribe((resp) => {
        //     if (resp.success) {
        //         this.api.balance = resp.data.currentBalance
        //         this.userBalance = resp.data.currentBalance
        //     } else {
        //         this.alert.error(resp.errors.general)
        //     }
        // })
        this.api.balance.subscribe((r:any)=> {
            this.userBalance = Number(r)
        })

        this.ds.getPaymentDetail().subscribe((resp) => {
            
            if (resp.success) {
               this.api.singleDayInspectionAmount = resp.data.singleDayInspectionAmount
                this.requiredBalance = Number(resp.data.singleDayInspectionAmount)
            }
        })



    }
    ngOnInit() {
        this.getCalendarView()
        this.search()
    }


    search() {
        this.filters = {
            startDate: moment(this.fIlterYear).clone().set('month', moment(this.fIlterMonth).month()).startOf('month').toDate(),
            endDate: moment(this.fIlterYear).clone().set('month', moment(this.fIlterMonth).month()).endOf('month').toDate(),
        };

        this.filters.startDate = moment(this.filters.startDate).format('YYYY-MM-DD');
        this.filters.endDate = moment(this.filters.endDate).format('YYYY-MM-DD');
        this.month = moment(this.fIlterMonth).format('MMMM');
        this.year = moment(this.fIlterYear).format('YYYY'); // Use this.fIlterYear
        this.fIlterMonth = this.month
        this.fIlterYear = this.year
        this.ds.getInspections(this.filters).subscribe((resp: any) => {
            if (resp.success) {
                this.waiting.search = false
                this.waiting.save = false
                resp.data.forEach((e: any) => {
                    let day = e.date
                    e.day = new Date(day).getDate()

                })
                this.mentors = resp.data
                this.datafound = false;
                resp.data.forEach((e: any) => {
                    if (e.inspections.length != 0) {
                        this.datafound = true;
                    }


                })

            }
            this.dataStatus = 'done'
        })
    }
    cancelButton(f: any) {
        this.modalRef.hide()
        f.resetForm()
    }
    checkDay(date: string) {
        return moment(date, "YYYY-MM-DD").format('MMMM') === this.month
    }
    getCalendarView() {
        const selectedMonth = moment(this.currentdDate); // Parse the selected month in 'YYYY-MM' format
        const startDay = selectedMonth.clone().startOf('month').startOf('week');
        const endDay = selectedMonth.clone().endOf('month').endOf('week');
        let date = startDay.clone().subtract(1, 'day')
        while (date.isBefore(endDay, 'day'))
            this.calendar.push({
                days: Array(7).fill(0).map(() => {
                    let day = date.add(1, 'day').clone()
                    return { day: Number(day.format('DD')), date: day.format('YYYY-MM-DD') }
                })
            })

    }



    get g() {
        return this.eventsForm.controls
    }

    save(f: any) {
        this.waiting.save = true
        if (this.eventsForm.invalid) {
            this.waiting.save = false
            this.alert.error('Please fill in all the details & try again!')
            return
        }
        if (
            Date.parse(this.eventsForm.controls.endDate.value) <
            Date.parse(this.eventsForm.controls.startDate.value)
        ) {
            this.waiting.save = false
            this.alert.error('End Date cannot be smaller than Start Date!')
            return
        }

        if (
            Date.parse(this.eventsForm.controls.startTime.value) ===
            Date.parse(this.eventsForm.controls.endTime.value)
        ) {
            this.alert.error(`Start time and end time can't be same!`)
            this.waiting.save = false
            return
        }

        if (
            Date.parse(this.eventsForm.controls.startTime.value) >
            Date.parse(this.eventsForm.controls.endTime.value)
        ) {
            this.alert.error(`End time can't be before start time`)
            this.waiting.save = false
            return
        }

        if (Date.parse(this.eventsForm.controls.endTime.value) - Date.parse(this.eventsForm.controls.startTime.value) < 3600000) {
            this.alert.error(`Event time should be at least of 1 hr!`)
            this.waiting.save = false
            return
        }

        const sDate: any = moment(this.eventsForm.controls.startDate.value).format('YYYY-MM-DD')
        const eDate: any = moment(this.eventsForm.controls.endDate.value).format('YYYY-MM-DD')
        const params = {
            id: this.eventsForm.controls.id.value,
            title: this.eventsForm.controls.title.value,
            content: this.eventsForm.controls.content.value,
            startDate: sDate,//this.eventsForm.controls.startDate.value,
            endDate: eDate,
            teamIds: this.teamIds
        }
    }
    openModal(modal: TemplateRef<any>, inspection: any, index: number) {
        this.selectedIndex = index
        this.inspectionIndex = this.mentors[this.selectedIndex].inspections.findIndex((item: any) => item.id === inspection.id);

        this.inspection = inspection
        // if (this.courseStudents === null) {
        //     return
        // }
        this.modalRef = this.ms.show(modal, {
            class: 'modal-lg modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }

    myBooking(ins:any){
        //inspection.bookings[0]?.status === 'accepted'
        console.log('inspection----', ins)
        let c = ins.bookings.findIndex((e:any)=>e.parallelId == this.api.user.id)
        console.log('index---',c)
        if(c > -1 && ins.bookings[c].status == 'accepted'){
           return ins.bookings[c].status
        }

        else if(c > -1 && ins.bookings[c].status == 'rejected'){
            return ins.bookings[c].status
        }
       else{
            return null
       } 
       
       
    }

    attendInspection(id: any, maximumBooking: any, currentBooking: any) {
        let data = {
            inspectionId: id
        }

        if (currentBooking >= maximumBooking) {
            this.alert.error('Maximum bookings limit reached')
            return
        }
        this.ds.attendInspection(data).subscribe((resp: any) => {
            if (resp.success) {
                console.log(this.mentors[this.selectedIndex].inspections[this.inspectionIndex]);
                this.mentors[this.selectedIndex].inspections[this.inspectionIndex] = resp.data
                this.alert.success('Booking created successfully')
                this.modalRef.hide()

                this.parallelName = this.api.user.parallel.name
                const params = { ...this.filters }

                this.ds.getMyWallet(params).subscribe((resp) => {
                    if (resp.success) {
                        // this.api.balance = resp.data.currentBalance
                        // this.userBalance = resp.data.currentBalance
                        this.api.balance.next(resp.data.currentBalance)
                    } 
                })
            } else {
                this.alert.error(resp.errors.general)
            }
        })
    }

    openRequestPayNowModal(template: any) {
        this.fetching = true
        this.payReqModal = this.ms.show(template, {
            class: 'modal-lg modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
        this.ds.getPaymentDetail().subscribe((resp) => {
            this.fetching = false
            if (resp.success) {
                if (resp.data != null) {
                    this.paymentDetail = resp.data
                    this.paymentDetail.perDayAmount =
                        this.paymentDetail.fifteenDayInspectionAmount / 15
                    if (
                        this.paymentDetail.perDayAmount <
                        this.paymentDetail.singleDayInspectionAmount
                    ) {
                        this.paymentDetail.savingPerDay =
                            this.paymentDetail.singleDayInspectionAmount -
                            this.paymentDetail.perDayAmount
                    }
                } else {
                }
            } else {
            }
        })
    }

    submitForm() {
        const form: any = window.document.getElementById('formAuthorizeNetTestPage')
        form.submit()
    }

    forEachInspection() {
        this.isPack2Active = false
        this.isPack1Active = true
        this.packageAmount = 0
    }
    forManyInspection() {
        this.isPack1Active = false
        this.isPack2Active = true
        this.packageAmount = this.paymentDetail.fifteenDayInspectionAmount
    }
    clearAmount() {
        this.isPack1Active = false
        this.isPack2Active = false
        this.packageAmount = 0
        setTimeout(() => {
            this.confirmPayment = false
        }, 500)
    }

    payRequest() {
        this.waiting.save = true
        let params = {
            amount: this.packageAmount,
            package: true
        }
        if (this.packageAmount !== this.paymentDetail.fifteenDayInspectionAmount) {
            params.package = false
        }
        this.ds.payRequest(params).subscribe((resp: any) => {
            this.waiting.save = false
            if (resp.success) {
                this.confirmPaymentDetails = resp.data
                this.confirmPaymentDetails.url = atob(resp.data.url)
                this.paymentFG.controls.token.setValue(resp.data.token)
                this.confirmPayment = true
            } else {
                this.alert.error(resp.errors.general)
            }
        })
    }


    checkEvent(p: any, date: any): any {
        if (p == date) {
            return true
        } else {
            return false
        }
    }


    formatTime(time: any) {
        const formatedTime = moment(time, 'HH:mm:ss');
        return formatedTime.format('h:mm:ss A');
    }

    nextMonth() {
        this.datafound = false
        this.calendar = []
        const currentDate = moment(this.currentdDate, 'YYYY-MM-DD');
        const nextMonth = currentDate.clone().add(1, 'month');
        this.currentdDate = moment(nextMonth.format('YYYY-MM-DD'))
        const today = this.currentdDate

        // const startDay = today.clone().startOf('month').startOf('week')
        // // console.log('startDay', startDay)
        // const endDay = today.clone().endOf('month').endOf('week')
        // // console.log('enddate', endDay)
        // let date = startDay.clone().subtract(1, 'day')
        // while (date.isBefore(endDay, 'day'))
        //     this.calendar.push({
        //         days: Array(7).fill(0).map(() => {
        //             let day = date.add(1, 'day').clone()
        //             return { day: Number(day.format('DD')), date: day.format('YYYY-MM-DD') }
        //         })
        //     })
        this.filters.startDate = this.currentdDate.startOf('month').toDate()
        this.filters.endDate = this.currentdDate.endOf('month').toDate()
        this.getCalendarView()
        console.log(this.filters)
        this.filters = {
            mentorIds: this.mentorId,
            startDate: moment(this.fIlterYear).clone().set('month', moment(this.currentdDate).month()).startOf('month').toDate(),
            endDate: moment(this.fIlterYear).clone().set('month', moment(this.currentdDate).month()).endOf('month').toDate(),
        };

        this.filters.startDate = moment(this.filters.startDate).format('YYYY-MM-DD');
        this.filters.endDate = moment(this.filters.endDate).format('YYYY-MM-DD');

        this.ds.getInspections(this.filters).subscribe((resp: any) => {
            if (resp.success) {
                this.waiting.search = false
                this.waiting.save = false
                resp.data.forEach((e: any) => {
                    let day = e.date
                    e.day = new Date(day).getDate()

                })
                this.mentors = resp.data
                this.datafound = false;
                resp.data.forEach((e: any) => {
                    if (e.inspections.length != 0) {

                    }


                })

            }
            this.datafound = true;
            this.dataStatus = 'done'
        })
        this.month = today.format('MMMM')
        this.year = today.format('YYYY')
        this.fIlterMonth = today.format('MMMM')
        this.fIlterYear = today.format('YYYY')
    }
    previousMonth() {
        this.datafound = false
        this.calendar = []
        const currentDate = moment(this.currentdDate, 'YYYY-MM-DD');
        const nextMonth = currentDate.clone().subtract(1, 'month');
        this.currentdDate = moment(nextMonth.format('YYYY-MM-DD'))
        const today = this.currentdDate

        // const startDay = today.clone().startOf('month').startOf('week')
        // // console.log('startDay', startDay)
        // const endDay = today.clone().endOf('month').endOf('week')
        // // console.log('enddate', endDay)
        // let date = startDay.clone().subtract(1, 'day')
        // while (date.isBefore(endDay, 'day'))
        //     this.calendar.push({
        //         days: Array(7).fill(0).map(() => {
        //             let day = date.add(1, 'day').clone()
        //             return { day: Number(day.format('DD')), date: day.format('YYYY-MM-DD') }
        //         })
        //     })
        this.filters.startDate = this.currentdDate.startOf('month').toDate()
        this.filters.endDate = this.currentdDate.endOf('month').toDate()
        this.getCalendarView()
        console.log(this.filters)
        this.filters = {
            mentorIds: this.mentorId,
            startDate: moment(this.fIlterYear).clone().set('month', moment(this.currentdDate).month()).startOf('month').toDate(),
            endDate: moment(this.fIlterYear).clone().set('month', moment(this.currentdDate).month()).endOf('month').toDate(),
        };

        this.filters.startDate = moment(this.filters.startDate).format('YYYY-MM-DD');
        this.filters.endDate = moment(this.filters.endDate).format('YYYY-MM-DD');

        this.ds.getInspections(this.filters).subscribe((resp: any) => {
            if (resp.success) {
                this.waiting.search = false
                this.waiting.save = false
                resp.data.forEach((e: any) => {
                    let day = e.date
                    e.day = new Date(day).getDate()

                })
                this.mentors = resp.data
                this.datafound = false;
                resp.data.forEach((e: any) => {
                    if (e.inspections.length != 0) {

                    }


                })

            }
            this.datafound = true;
            this.dataStatus = 'done'
        })
        this.month = today.format('MMMM')
        this.year = today.format('YYYY')
        this.fIlterMonth = today.format('MMMM')
        this.fIlterYear = today.format('YYYY')
    }
    fIlterMonthYear() {
        this.datafound = false
        let month = moment(this.fIlterMonth, 'MMMM').month()
        let year = moment(this.fIlterYear).year()
        console.log('fIlterMonth', this.fIlterMonth)
        console.log('month', month)
        console.log('year', year)
        this.currentdDate = moment().month(month).year(year)
        console.log('currentdDate', this.currentdDate)

        this.year = String(year)
        this.month = this.currentdDate.format('MMMM')
        this.filters.startDate = this.currentdDate.startOf('month').toDate()
        this.filters.endDate = this.currentdDate.endOf('month').toDate()
        this.calendar = []
        console.log(this.month)
        const startDay = this.currentdDate.clone().startOf('month').startOf('week')
        const endDay = this.currentdDate.clone().endOf('month').endOf('week')
        let date = startDay.clone().subtract(1, 'day')
        while (date.isBefore(endDay, 'day')) {
            this.calendar.push({
                days: Array(7).fill(0).map(() => {
                    let day = date.add(1, 'day').clone()
                    return { day: Number(day.format('DD')), date: day.format('YYYY-MM-DD') }
                })
            })
        }
        this.filters = {
            mentorIds: this.mentorId,
            startDate: moment(this.fIlterYear).clone().set('month', moment(this.fIlterMonth).month()).startOf('month').toDate(),
            endDate: moment(this.fIlterYear).clone().set('month', moment(this.fIlterMonth).month()).endOf('month').toDate(),
        };

        this.filters.startDate = moment(this.filters.startDate).format('YYYY-MM-DD');
        this.filters.endDate = moment(this.filters.endDate).format('YYYY-MM-DD');

        this.ds.getInspections(this.filters).subscribe((resp: any) => {
            if (resp.success) {
                this.waiting.search = false
                this.waiting.save = false
                resp.data.forEach((e: any) => {
                    let day = e.date
                    e.day = new Date(day).getDate()

                })
                this.mentors = resp.data
                this.datafound = false;
                resp.data.forEach((e: any) => {
                    if (e.inspections.length != 0) {

                    }


                })

            }
            this.dataStatus = 'done'
            this.datafound = true;
        })
    }

    withId() {
        this.datafound = false;
        this.filters = {
            mentorIds: this.mentorId,
            startDate: moment(this.fIlterYear).clone().set('month', moment(this.currentdDate).month()).startOf('month').toDate(),
            endDate: moment(this.fIlterYear).clone().set('month', moment(this.currentdDate).month()).endOf('month').toDate(),
        };

        this.filters.startDate = moment(this.filters.startDate).format('YYYY-MM-DD');
        this.filters.endDate = moment(this.filters.endDate).format('YYYY-MM-DD');

        this.ds.getInspections(this.filters).subscribe((resp: any) => {
            if (resp.success) {
                this.waiting.search = false
                this.waiting.save = false
                resp.data.forEach((e: any) => {
                    let day = e.date
                    e.day = new Date(day).getDate()

                })
                this.mentors = resp.data
                this.datafound = false;
                resp.data.forEach((e: any) => {
                    if (e.inspections.length != 0) {

                    }

                    this.datafound = true;
                })

            }
            this.dataStatus = 'done'
        })
    }
    // teamSelect(): void {
    //     this.teamIds = this.g.teamIds.value
    //     if (this.teamIds.includes(0)) {
    //         this.g.teamIds.setValue([0])
    //         this.teamIds = []
    //         this.teamsList.forEach((element: any) => {
    //             this.teamIds.push(element.id)
    //         });
    //     }
    // }

    // startTimeChange() {
    //     //Endtime advances as start time advances
    //     const currentStartTime = moment(this.eventsForm.controls.startTime.value)
    //     const edTime = this.prevStartTime
    //     let diff = currentStartTime.diff(edTime)
    //     let duration = moment.duration(diff)
    //     console.log()
    //     let endtime = moment(this.eventsForm.controls.endTime.value)
    //         .add(duration.asMinutes(), 'minutes')
    //         .toString()
    //     this.eventsForm.controls.endTime.setValue(endtime)
    //     this.prevStartTime = currentStartTime
    // }


    checkBooking(ins:any){
        console.log('cin---',ins)
        // let ind = this.inspectionList.findIndex((f => f.id == id))
        let cInsp = ins

        if(cInsp?.currentBookings == cInsp?.maxBookings){
            return false
        }
       
        if(cInsp?.bookings.length > 0){
            let cInd =    cInsp?.bookings.findIndex((p:any) => p.parallelId == this.api.user.id)
            if(cInd > -1){
                return false
            }
        }
        
         return true
    }

    checkTime(inspectonDate: any, inspectionTime: any) {
        // Get the current time and add 15 minutes to it
        console.log('calling--fun',inspectonDate,'----',inspectionTime)
        let currentTime = moment().add(15, 'minute');
    
        if (inspectionTime && inspectonDate != null) {
            // Parse and combine the inspection date and time into a single moment object
            let inspectionDateTime = moment(`${inspectonDate} ${inspectionTime}`, 'YYYY-MM-DD HH:mm:ss');
            
            // Log for debugging
            console.log('Current Time (+15 mins):', currentTime.format('YYYY-MM-DD HH:mm:ss'));
            console.log('Inspection DateTime:', inspectionDateTime.format('YYYY-MM-DD HH:mm:ss'));
    
            // Check if the combined inspection date and time is valid
            if (!inspectionDateTime.isValid()) {
                console.log('Invalid inspection date or time format.');
                return false;
            }
    
            // Compare the current time with the inspection time
            if (currentTime.isBefore(inspectionDateTime)) {
                console.log('Current time is less than the inspection date/time.');
                return true;
            } else {
                console.log('Current time is greater than or equal to the inspection date/time.');
                return false;
            }
        }
    
        return false;
    }

    checkBookingStatus(id:any){
        // let ind = this.inspectionList.findIndex((f => f.id == id))
        // let cInsp = this.inspectionList[ind]

        // if(cInsp?.currentBookings == cInsp?.maxBookings){
        //     return false
        // }

        // if(cInsp?.bookings.length > 0){
        //     let cInd =    cInsp?.bookings.findIndex((p:any) => p.parallelId == this.api.user.id)
        //     if(cInd > -1){
        //         if(cInsp[cInd].bookings.status == 'accepted'){
        //             this.bookingStatus = 'Booked'
                   
        //         }

        //         if(cInsp[cInd].bookings.status == 'rejected'){
        //             this.bookingStatus = 'Rejected'
                   
        //         }

                
        //     }
        // }
        // console.log('booking---status', this.bookingStatus)
      
    }

    checkMyBooking(id:any){
        // let ind = this.inspectionList.findIndex((f => f.id == id))
        // let cInsp = this.inspectionList[ind]
        // if(cInsp?.bookings.length > 0){
        //     let cInd =    cInsp?.bookings.findIndex((p:any) => p.parallelId == this.api.user.id)
        //     if(cInd > -1){
        //         console.log('bookin---s',cInsp.bookings[cInd].status)
        //       this.bookingStatus = cInsp.bookings[cInd].status == 'accepted'?'Booked':'Rejected'
        //         return true
                
        //     }
        // }
        return false
    }

}
