<!-- <h1 class="title">Events Monthly View</h1> -->
<app-breadcrums [data]="breadCrum"></app-breadcrums>
<div class="month-title" style="margin: 10px 0px;">
    <h1>{{ this.month }}, {{ this.year }}</h1>
</div>
<div class="calendar-header">


    <!-- <div class="col-md-3">
        <label>Team</label>
        <ng-select class="ng-select" [(ngModel)]="this.teamIds" [multiple]="true" name="teams" placeholder="Please Select Team" (ngModelChange)="search()">
            <ng-container *ngFor="let team of teamsList">
                <ng-option [value]="team.id" *ngIf="team.status === 'Active'">{{ team.title }}</ng-option>
            </ng-container>
        </ng-select>
    </div> -->
    <div class="direction-icons col-md-2">
        <button class="cg" (click)="previousMonth()"><i class="fas fa-chevron-left"></i></button>
        <button class="cg ms-2" (click)="nextMonth()"><i class="fas fa-chevron-right"></i></button>
    </div>
    <div class="col-md-2 cg-input-group">
        <label>Mentors</label>
        <ng-select [(ngModel)]="mentorId" [multiple]="true" [clearable]="false" placeholder="Select Mentor(s)"
            (ngModelChange)="withId()">
            <!-- <ng-option [value]="!instructorIds">Select Instructor</ng-option> -->
            <!-- <ng-option value="">Select All</ng-option> -->
            <ng-option *ngFor="let item of mentorList" [value]="item.id">{{ item.name }}</ng-option>
        </ng-select>
    </div>
    <div class="col-md-2">
        <input readonly bsDatepicker
            [bsConfig]="{ minMode: 'month', containerClass: 'theme-orange', dateInputFormat: 'MMMM', adaptivePosition: true, showWeekNumbers: false }"
            class="cg" style="background-color: #fff" [(ngModel)]="fIlterMonth" (ngModelChange)="fIlterMonthYear()"
            placement="left" placeholder="Select Month" />
    </div>
    <div class="col-md-2">
        <input readonly bsDatepicker
            [bsConfig]="{ minMode: 'year', containerClass: 'theme-orange', dateInputFormat: 'YYYY', adaptivePosition: true, showWeekNumbers: false }"
            class="cg" style="background-color: #fff" [(ngModel)]="fIlterYear" (ngModelChange)="fIlterMonthYear()"
            placement="left" placeholder="Select Year" />
    </div>

    <!-- <div class="col-md-2">
                <button [disabled]="waiting.save" [ngClass]="{ 'in-progress': waiting.save }" type="button" class="cg secondary"
                (click)="getCalendarView()" style="margin-top: 18px;">
                search
                <span></span>
                <span></span>
                <span></span>
            </button>
            </div> -->




    <div class="legends col-lg-6 col-md-4 col-sm-12">
        <div class="legend makeup">
            <span class="daba" [style.background-color]="scheduled"></span>
            <div>Scheduled</div>
        </div>

        <div class="legend free-slot">
            <span class="daba" [style.background-color]="inProgress"></span>
            <div>In Progress</div>
        </div>

        <div class="legend blocked-slot">
            <span class="daba" [style.background-color]="canceled"></span>
            <div>Canceled</div>
        </div>
        <div class="legend blocked-slot">
            <span class="daba" [style.background-color]="completed"></span>
            <div>Completed</div>
        </div>
    </div>
</div>
<div class="box" style="margin-top: 10px; ">
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th *ngFor="let d of days; let i = index" class="days-color">{{ d }}</th>
                </tr>
            </thead>
            <!-- <tbody *ngIf="dataStatus === 'fetching'" [appSkeletonTabelLoader]="loaderOptions"></tbody> -->
            <tbody>
                <tr *ngFor="let week of this.calendar; let i; index">
                    <td *ngFor="let weekDay of week.days">
                        <span [class.blur]="!checkDay(weekDay.date)">{{ weekDay.day }}</span>
                        <ng-container>
                            <div class="party" *ngFor="let mentor of mentors; let m = index">

                                <ng-container *ngFor="let d of mentor.inspections; let i=index">

                                    <div *ngIf="checkEvent(d.date, weekDay.date)" class="color-block"
                                        [class.scheduled]="d.status == 'scheduled'"
                                        [class.inProgress]="d.status == 'inProgress'"
                                        [class.completed]="d.status == 'completed'"
                                        [class.canceled]="d.status == 'canceled'" style="cursor: pointer;"
                                        (click)="openModal(parallel, d, m)">
                                        <p *ngIf="d.parallels.length < 100">{{d.parallels.length}}</p>
                                        <p *ngIf="d.parallels.length > 99" style="font-size: 12px; margin-top: 2px;">90+
                                        </p>
                                        <span class="tooltips">
                                            Mentor: {{mentor.firstName}} {{mentor.lastName}}
                                            <div class="d-flex flex-row justify-content-between">
                                                <!-- Complete Address: {{ d.house }},{{d.street}},{{d.city}},{{d.state}},{{d.zipCode}} <br /><br /> -->
                                            
                                                Complete Address: {{ inspection?.address }} <br/> {{inspection?.addressTwo}} <br/>{{inspection?.city}},{{inspection?.zipCode}}
                                            </div>
                                            <!-- Time: {{formatTime(d.startTime) }} <br /> -->
                                                Start Time: {{moment(d.startTime, 'HH:mm').format('hh:mm A')}}<br />
                                                <!-- End Time: {{moment(d.endTime, 'HH:mm').format('hh:mm A')}}<br /> -->
                                        </span>
                                    </div>

                                </ng-container>
                            </div>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
            <!-- <tbody *ngIf="!datafound && dataStatus == 'done'">
                <tr>
                    <td colspan="4">
                        <app-no-data-found></app-no-data-found>
                    </td>
                </tr>
            </tbody> -->
        </table>
    </div>
</div>
<!-- <ng-template #parallel>
    <div class="modal-header" style="flex-wrap: wrap;">
        <p class="secondary w-100" style="font-weight: 600;">{{inspection.address}}</p>
        <p style="font-size: 12px;">{{inspection.date}} / {{ moment(inspection.startTime, 'HH:mm:ss').format('hh:mm A')
            }}</p>
    </div>
    <div class="container scrolling back-office-panel-cg">
        <div class="table-responsive" style="overflow-y: scroll; height: 300px;">
            <table class="table">
                <thead>
                    <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Contact</th>
                      
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of inspection.parallels" class="h-tr">
                        <td class="text-left" style="height: 40px !important; border: none;">{{item.name}}</td>
                        <td class="text-left" style="height: 40px !important; border: none;">{{item.contactOne}}</td>
                        
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <button type="button" class=" cg ml-3 secondary" style="width: fit-content;"
            (click)="modalRef.hide()">Cancel</button>
        <div>
            <button class="cg" style="background: green" disabled
                *ngIf="inspection.bookings.length >  0 && myBooking(inspection) == 'accepted'">Booked</button>

                <button class="cg danger" disabled
                *ngIf="inspection.bookings.length >  0 && myBooking(inspection) == 'rejected'">Rejected</button>
            <button class="cg info"
                (click)="api.balance < api.singleDayInspectionAmount ? openRequestPayNowModal(requestPayNowTemplate) : attendInspection(inspection.id, inspection.maxBookings, inspection.currentBookings)"
                *ngIf="inspection.bookings.length === 0 && inspection.currentBookings < inspection.maxBookings">Attend
            </button> 
            <ng-container  *ngIf="userBalance >= requiredBalance && myBooking(inspection)== null">
                <button class="cg info"
                        (click)="attendInspection(inspection.id, inspection.maxBookings, inspection.currentBookings)"
                    >Attend
                </button>
            </ng-container>
            
            <ng-container *ngIf="userBalance < requiredBalance && myBooking(inspection)== null">
                <button class="cg info"
                        (click)="openRequestPayNowModal(requestPayNowTemplate)"
                        >Attend
                </button>
            </ng-container>
        </div>
    </div>
    <div class=" col-md-12 mb-12 mt-4">
    </div>
</ng-template> -->

<ng-template #parallel>
    <div class="modal-header">
        <h1 class="modal-title pull-left">Detail</h1>
    </div>
    <div class="modal-body">
        <div class="row">
            <!-- <div class="col-md-4">
                <h3>Address:</h3>
                {{ requestDetail.address }}
            </div> -->

            <div class="col-md-4">
                <h3>Date:</h3>
                {{ inspection.date }}
            </div>

            <div class="col-md-4">
                <h3>Time:</h3>
                {{ inspection.startTime }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-md-12">
                <h3>Description:</h3>
                {{ inspection.description }}
            </div>
        </div>

        <div class="row mt-4">
            <!-- <div class="col-md-4">
                <h3>Complete Address:</h3>
                {{ inspection.house }},{{inspection.street}},{{inspection.city}},{{inspection.state}},{{inspection.zipCode}}
            </div> -->

            <div class="col-md-4">
                <h3>Complete Address:</h3>
                {{ inspection.address }} <br/> {{inspection.address2}} <br/>{{inspection.city}},{{inspection.zipCode}}
            </div>

            <!-- <div class="col-md-4">
                <h3>State:</h3>
                {{ requestDetail.state }}
            </div> -->

            <div class="col-md-4">
                <h3>Status:</h3>
                {{ inspection.status }}
            </div>
        </div>

        <div class="row mt-4">
            <!-- <div class="col-md-4">
                <h3>Zip-Code:</h3>
                {{ requestDetail.zipCode }}
            </div> -->

            <!-- <div class="col-md-4">
                <h3>MaxBookings:</h3>
                {{ inspection.maxBookings }}
            </div>

            <div class="col-md-4">
                <h3>Mentor Name:</h3>
                {{ inspection.mentor.name }}
            </div> -->
        </div>
    </div>

    <div class="modal-footer">
        <ng-container *ngIf="checkTime(inspection.date,inspection.startTime)">
            <button class="cg info" (click)="attendInspection(inspection.id, inspection.maxBookings, inspection.currentBookings)" *ngIf="checkBooking(inspection)">Attend</button>
        </ng-container>
        <ng-container *ngIf="inspection?.bookings?.length > 0">
            <!-- <button class="cg danger" *ngIf="inspection.bookings.length > 0 && inspection?.bookings[0]?.status === 'rejected'">Rejected</button>
            <button class="cg" style="background: green" *ngIf="inspection.bookings.length > 0 && inspection.bookings[0]?.status === 'accepted'">Booked</button> -->
        </ng-container>
        
        <button type="button" class="cg primary ms-2" (click)="modalRef.hide()">Close</button>
    </div>
</ng-template>

<ng-template #requestPayNowTemplate>
    <ng-container> </ng-container>
    <div class="modal-header" [ngStyle]="{ padding: waiting.save ? '0' : '50px' }"
        style="background: rgba(221, 221, 221, 1)" *ngIf="waiting.save || fetching">
        <div class="image-wrapper"><img src="/assets/images/rolling-main.svg" alt="" /></div>
    </div>
    <div class="modal-body" style="background: rgba(221, 221, 221, 1)" *ngIf="!fetching">
        <div class="container-package" *ngIf="!confirmPayment">
            <div class="packages">
                <span class="borders"></span>
                <div class="pack1" [ngClass]="{ active: isPack2Active }" (click)="forManyInspection()">
                    <img src="/assets/images/rectangle.png" alt="" class="package-img" />
                    <div class="text">
                        <div>
                            <h2>15 Inspections In</h2>
                            <h1>{{ paymentDetail.fifteenDayInspectionAmount | number: '1.2-2' }}$</h1>
                        </div>
                        <div>
                            <h3>Per inspection {{ paymentDetail.perDayAmount | number: '1.2-2' }}$</h3>
                            <h3 *ngIf="paymentDetail.savingPerDay">{{ paymentDetail.savingPerDay | number: '1.2-2' }}$
                                per inspection saving</h3>
                        </div>
                    </div>
                </div>
                <div class="pack2" [ngClass]="{ active: isPack1Active }" (click)="forEachInspection()">
                    <img src="/assets/images/rectangle.png" alt="" class="package-img" />
                    <div class="text" style="padding: 10px 20px; text-align: center">
                        <h2>Add balance to your wallet</h2>
                        <h3>* Pay for each inspection at {{ paymentDetail.singleDayInspectionAmount }}$, 15th is free
                        </h3>
                        <!-- <div>
                            <h2>Pay for each inspection at</h2>
                            <h1>{{ inspection1 }}$</h1>
                        </div>
                        <div>
                            <h3>15th is free</h3>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="amount" *ngIf="isPack1Active || isPack2Active">
                <div style="display: flex; gap: 1.5rem; align-items: center; flex-wrap: wrap" *ngIf="isPack1Active">
                    <h2 style="font-size: 28px">Amount:</h2>
                    <input style="border: 0; outline: 0; width: 80px; font-size: 24px" type="number"
                        [(ngModel)]="packageAmount" />
                </div>
                <div style="display: flex; gap: 1.5rem; align-items: center; flex-wrap: wrap"
                    *ngIf="packageAmount == paymentDetail.fifteenDayInspectionAmount && isPack2Active">
                    <h2 style="font-size: 28px">Amount:</h2>
                    <h1>{{ packageAmount }}$</h1>
                </div>
                <button [disabled]="packageAmount != 0 && waiting.save" class="pac-pay" type="button"
                    (click)="payRequest()" *ngIf="packageAmount !== 0 && packageAmount != null">Pay Now</button>
            </div>
            <div class="amount" *ngIf="packageAmount <= 0 && !isPack1Active">
                <h2>Please Select Option</h2>
            </div>
        </div>

        <div class="payment-confirmation" *ngIf="confirmPayment">
            <p class="heading">Are you sure to pay ${{ packageAmount }}?</p>
            <form [formGroup]="paymentFG" method="post" [action]="this.confirmPaymentDetails.url"
                id="formAuthorizeNetTestPage" name="formAuthorizeNetTestPage" (ngSubmit)="submitForm()">
                <input type="hidden" name="token" formControlName="token" />
                Continue to Authorize.net to Payment Page
                <button type="submit" class="cg success" style="margin-left: 20px">Continue to next page</button>
            </form>
        </div>
        <div class="modal-footer" style="background: rgba(221, 221, 221, 1)">
            <button type="button" class="cg primary ms-2" (click)="payReqModal.hide(); clearAmount()">Cancel</button>
        </div>
    </div>
</ng-template>
<!-- <ng-template #addEditTemplate>
    <div class="modal-header">
        <h1 class="title">{{ modalTitle }}</h1>
    </div>
    <div class="modal-body">
        <form [formGroup]="eventsForm" #f="ngForm">
            <div class="row">
                <div class="col-md-6">
                    <label>Title <span class="mendatory">*</span></label>
                    <input type="text" formControlName="title" class="cg form-control mt-0" placeholder="Enter Title" />
                    <div *ngIf="ui.checkErrors(g.title, f)" class="errors">
                        <p *ngIf="g.title.errors?.required">Title is required</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <label>Teams <span class="mendatory">*</span></label>
                    <div class="cg-input-group" style="width: 100%">
                         <ng-select class="ng-select" [(ngModel)]="this.teamIds" formControlName="teamIds" placeholder="Select Teams" [multiple]="true" [ngClass]="{ 'form-submitted': f.submitted }">
                        <ng-select class="ng-select" formControlName="teamIds" placeholder="Select Teams"
                            [multiple]="true" [ngClass]="{ 'form-submitted': f.submitted }" (change)="teamSelect()">
                            <ng-option [value]="0">All Teams </ng-option>
                            <ng-option *ngFor="let c of teamsList" [value]="c.id">{{ c.title }}</ng-option>
                        </ng-select>
                    </div>
                    <div *ngIf="ui.checkErrors(g.teamIds, f)" class="errors">
                        <p *ngIf="g.teamIds.errors?.required">Team is required</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <label>Start Date <span class="mendatory">*</span></label>
                    <input type="text" class="cg" formControlName="startDate" placeholder="Enter Start Date"
                        bsDatepicker
                        [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }"
                        readonly [ngClass]="{ 'form-submitted': f.submitted }" />
                    <div *ngIf="ui.checkErrors(g.startDate, f)" class="errors">
                        <p *ngIf="g.startDate.errors?.required">Start date is required</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <label>End Date <span class="mendatory">*</span></label>
                    <input type="text" class="cg" formControlName="endDate" placeholder="Enter End Date" bsDatepicker
                        [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }"
                        readonly [ngClass]="{ 'form-submitted': f.submitted }" />
                    <div *ngIf="ui.checkErrors(g.endDate, f)" class="errors">
                        <p *ngIf="g.endDate.errors?.required">End date is required</p>
                    </div>
                </div>
                <div class="col-md-6 mt-2">
                    <div class="cg input-group" style="position: relative">
                        <label class="mb-0">Start Time <span class="mendatory">*</span></label>
                        <timepicker id="timepicker" class="cg time-wrapper" formControlName="startTime"
                            (ngModelChange)="startTimeChange()" [ngClass]=" { 'form-submitted' : f.submitted }">
                        </timepicker>
                    </div>
                    <div *ngIf="ui.checkErrors(g.startTime, f)" class="errors">
                        <p *ngIf="g.startTime.errors?.required">Start Time is required</p>
                    </div>
                </div>
                <div class="col-md-6 mt-2">
                    <label class="mb-0">End Time <span class="mendatory">*</span></label>
                    <timepicker id="timepicker" class="cg time-wrapper" formControlName="endTime"
                        [ngClass]="{ 'form-submitted': f.submitted }"></timepicker>
                    <div *ngIf="ui.checkErrors(g.endTime, f)" class="errors">
                        <p *ngIf="g.endTime.errors?.required">End Time is required</p>
                    </div>
                </div>
                <div class="content-responsive">
                    <div class="col-md-12 content-height editor-height">
                        <label class="mb-">Content<span class="mendatory">*</span></label>
                        <quill-editor formControlName="content" [modules]="editorConfig"
                            [ngClass]="{ 'form-submitted': f.submitted }"></quill-editor>
                        <div *ngIf="ui.checkErrors(g.content, f)" class="errors">
                            <p *ngIf="g.content.errors?.required">Content is required</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btns">
                <div class="col-md-12 d-flex justify-content-end">
                    <button type="button" id="btns2" class="cg warning" (click)="cancelButton(f)">Cancel</button>
                    <button type="button" id="btns2" (click)="save(f)" class="cg primary ms-2"
                        [ngClass]="{ 'in-progress': waiting?.save }">
                        Save
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template> -->